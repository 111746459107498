import { useState } from 'react';
import { useSelector } from 'react-redux';
import './style/index.scss';
import { Button, Input } from 'antd';
import api from '@/api/login';
import { useNavigate } from 'react-router-dom';
import '../../assets/animate.css';
import useLoading from '@/hook/useLoading';
import HomeFooter from './components/home-footer';
const Teams = () => {
    const thisYear = window.utils.getCurremtYear();
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const { deviceType } = useSelector((state: any) => state.state);
    const [state, setState] = useState({
        email: '',
    });
    const vaildeEmail = () => {
        if (!state.email || !window.utils.isEmail(state.email)) {
            window.format.alert('error', 'Please enter a valid email address');
            return false;
        }
        return true;
    };
    const nextStep = () => {
        if (!vaildeEmail()) return;
        setLoadingStatus(true);
        const pat = {
            email: state.email,
        };
        api.userSubscribe(pat, res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                setLoadingStatus(false);
                setState({ email: '' });
                window.format.alert('success', 'Subscribed successfully');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="home-page policy-main team-main">
            <section className="content-box ">
                <div className="r-left contentTop_box">
                    <img
                        style={{ cursor: 'pointer' }}
                        src={require('@/assets/images/website/logo.png')}
                        className="logo"
                        alt=""
                        onClick={() => navigate('/webSite')}
                    />
                    <Button
                        className="r-primaryHollow_btn join_btn_top"
                        onClick={() => navigate('/completeInformation')}
                    >
                        JOIN WAITLIST
                    </Button>
                </div>
                <section className="r-center">
                    <div className="textContent-box">
                        <p className="fz16">Current as of 1 July 2024</p>
                        <p className="fz-title fz48">WEBSITE TERMS</p>
                        <p className="fz20" style={{ marginBottom: 96 }}>
                            By using this website you agree to be bound by the terms and conditions set out on this
                            webpage. If you do not accept these terms and conditions, you must refrain from using this
                            website.
                            <br />
                            <br />
                            This website is owned and operated by NARDO EXPERIENCE HOLDINGS PTY LTD ABN 81 673 060 789
                            (referred to in these terms and conditions as 'we', 'us', or 'our').
                        </p>
                        <p className="fz-title">INTELLECTUAL PROPERTY</p>
                        <p>
                            Unless otherwise indicated, all materials used on this website (including text, graphics,
                            logos, icons, sound recordings and software) are subject to copyright, trade mark or other
                            intellectual property rights that are owned or licensed by us. You may only access and use
                            these materials for non-commercial or personal use, except to the extent permitted by law or
                            where you have received prior written approval from us.
                        </p>
                        <p className="fz-title">LIABILITY</p>
                        <p>
                            We make no warranties or representations about this website or any of its content and will
                            not be responsible to you or any third party for any direct or consequential loss suffered
                            in connection with the use of this website. To the maximum extent permitted by law, we
                            exclude any liability that may arise due to your use of our website and/or the information
                            or materials contained on it. You agree to indemnify us for any loss or liability arising
                            out of your use of this site.
                        </p>
                        <p className="fz-title">EXTERNAL LINKS</p>
                        <p>
                            Our website may contain links to websites operated by third parties. Those links are
                            provided for convenience and may not remain current or be maintained. Unless expressly
                            stated otherwise, we do not endorse and are not responsible for the content on those linked
                            websites and have no control over or rights in those linked websites.
                        </p>
                        <p className="fz-title">UPDATES</p>
                        <p>
                            We may change these terms and conditions at any time by updating this webpage and your
                            continued use of the website following such update will represent an agreement by you to be
                            bound by the terms and conditions as amended.
                        </p>
                        <p className="fz-title">JURISDICTION</p>
                        <p>
                            Your use of the website and these terms and conditions are governed by the laws of NSW,
                            Australia.
                        </p>
                    </div>
                </section>
            </section>
            <HomeFooter showTopLine={deviceType === 'pc'}></HomeFooter>
        </div>
    );
};
export default Teams;
