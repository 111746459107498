import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Input } from 'antd';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setUserInfo } from '@/store/stateSlice';
import SignImg from '@/assets/images/website/sign-img.png';
import Logo from '@/assets/images/website/logo.png';
import api from '@/api/login';
import useLoading from '@/hook/useLoading';
import './index.scss';

import Club1 from '@/assets/images/website/1.svg';
import Club2 from '@/assets/images/website/2.svg';
import Club3 from '@/assets/images/website/3.svg';
import Club4 from '@/assets/images/website/4.svg';
import Club5 from '@/assets/images/website/5.svg';
import Club6 from '@/assets/images/website/6.svg';
import Club7 from '@/assets/images/website/7.svg';

const clubLists = [Club1, Club2, Club3, Club4, Club5, Club6, Club7];
const isNotProd = process.env.REACT_APP_ENV !== 'prod';

const phoneNumberPattern = /^(04\d{8}|4\d{8}|\+61\d{9})$/;

const emailReg = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z]{2,4})+$/;

const CompleteInformation = () => {
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const { deviceType } = useAppSelector(state => state.state);
    const { userInfo } = useAppSelector(state => state.state);
    const dispatch = useAppDispatch();
    const [isError, setIsError] = useState(false);
    const [placeholderText, setPlaceholderText] = useState('Email address');
    const [fullNameError, setFullNameError] = useState(false);
    const [clubNameError, setClubNameError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [state, setState] = useState({
        fullName: '',
        clubName: '',
        phone: '',
        email: '',
    });
    const vaildeEmail = () => {
        if (!state.email || !window.utils.isEmail(state.email) || !emailReg.test(state.email)) {
            setPlaceholderText('Please enter a valid email address');
            // setState({ email: '' });
            setIsError(true);
            return false;
        }
        setPlaceholderText('Email address');
        setIsError(false);
        return true;
    };
    const vaildeForm = () => {
        const emailErr = vaildeEmail();
        let status = false;
        setFullNameError(!state.fullName);
        setClubNameError(!state.clubName);
        if (!state.phone || !phoneNumberPattern.test(state.phone)) {
            setPhoneError(true);
            setState({ ...state, phone: '' });
            status = true;
        } else {
            setPhoneError(false);
            status = false;
        }
        return !state.fullName || !state.clubName || !emailErr || status;
    };
    const nextStep = async () => {
        if (vaildeForm()) return;
        register();
        // setLoadingStatus(true);
        // const pat = {
        //     email: state.email,
        // };
        // if (isNotProd) {
        //     register();
        // } else {
        //     api.verifySubscribe(pat, res => {
        //         const { code, message } = res;
        //         if (code === 200) {
        //             register();
        //         } else if (code === 7000) {
        //             setPlaceholderText('Email has already been registered');
        //             setIsError(true);
        //             setLoadingStatus(false);
        //         } else {
        //             setLoadingStatus(false);
        //             window.format.alert('error', message, code);
        //         }
        //     });
        // }
    };
    const register = () => {
        const data = {
            email: state.email,
            fullName: state.fullName,
            clubName: state.clubName,
        };
        setLoadingStatus(true);
        api.userOtherSubscribe({ ...data, phone: state.phone }, res => {
            const { code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                const obj = Object.assign({ ...userInfo }, { ...state });
                dispatch(setUserInfo(obj));
                navigate(`/join-success`);
            } else {
                window.format.alert('error', message, code);
            }
        });
        api.userSubscribe({ ...data, phoneNumber: state.phone });
    };
    return (
        <div className="login-main">
            <div className="login-box">
                <img src={Logo} className="logo_img" alt="" onClick={() => navigate('/webSite')} />
                <div className="login-form">
                    <h1>
                        JOIN THE BETA <br />
                        WAITLIST
                    </h1>
                    <p className="formTop_tip">
                        Nardo is inviting a limited number of select clubs to become foundational members of the Nardo
                        Experience. Register your interest in becoming one of our early adopter clubs when we go live
                        towards the end of the 2024 season.
                    </p>
                    {/* <div className="baseSize" style={{ width: '100%' }}>
                        <Input
                            className="r-input r-round-input"
                            maxLength={250}
                            value={userInfo.email}
                            placeholder="Email address"
                            readOnly
                        />
                    </div> */}
                    <div className="baseSize" style={{ width: '100%' }}>
                        <Input
                            className={`r-input r-round-input`}
                            maxLength={150}
                            status={isError ? 'error' : ''}
                            value={state.email}
                            autoComplete="off"
                            onBlur={vaildeEmail}
                            onInput={(e: any) => {
                                setState({ ...state, email: e.target.value });
                                isError && setIsError(false);
                            }}
                            placeholder={'Email address'}
                            // suffix={isError ? <i className="err-tip"></i> : <></>}
                        />
                        <p className="error-tip">{isError ? placeholderText : ''}</p>
                    </div>
                    <div className="baseSize inputMar9" style={{ width: '100%' }}>
                        <Input
                            className="r-input r-round-input"
                            maxLength={50}
                            value={state.fullName}
                            status={fullNameError ? 'error' : ''}
                            onInput={(e: any) => {
                                const value = e.target.value;
                                const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
                                setState({ ...state, fullName: capitalizedValue });
                                fullNameError && setFullNameError(false);
                            }}
                            placeholder={'Full name'}
                        />
                        <p className="error-tip">{fullNameError ? 'Please enter your full name' : ''}</p>
                    </div>
                    <div className="baseSize" style={{ width: '100%' }}>
                        <Input
                            className="r-input r-round-input"
                            maxLength={100}
                            value={state.clubName}
                            autoComplete="off"
                            status={clubNameError ? 'error' : ''}
                            onInput={(e: any) => {
                                setState({ ...state, clubName: e.target.value });
                                clubNameError && setClubNameError(false);
                            }}
                            placeholder={'Club name'}
                        />
                        <p className="error-tip">{clubNameError ? 'Please enter a club name' : ''}</p>
                    </div>
                    <div className="baseSize mart9">
                        <Input
                            className="r-input r-round-input"
                            type="tel"
                            value={state.phone}
                            autoComplete="off"
                            maxLength={window.utils.isStartsWith04(state.phone) ? 10 : 9}
                            // maxLength={10}
                            onInput={(e: any) => {
                                e.target.value = e.target.value.replace(/[^0-9+]/g, '');
                                setState({ ...state, phone: e.target.value });
                                phoneError && setPhoneError(false);
                            }}
                            status={phoneError ? 'error' : ''}
                            placeholder={'Phone number'}
                        />
                        <p className="error-tip">{phoneError ? 'Please enter a valid phone number' : ''}</p>
                    </div>
                    <div className="baseSize">
                        <Button className="r-primary_btn" onClick={() => nextStep()}>
                            JOIN THE WAITLIST
                        </Button>
                    </div>
                    <p className="policy-tip">
                        By continuing, you agree to Nardo's
                        <a
                            className="linkBox"
                            target="_blank"
                            href={`${process.env.REACT_APP_WEB_URI}/terms`}
                            rel="noreferrer"
                        >
                            Terms of Service
                        </a>
                        and
                        <a
                            className="linkBox"
                            target="_blank"
                            href={`${process.env.REACT_APP_WEB_URI}/policy`}
                            rel="noreferrer"
                        >
                            Privacy Policy
                        </a>
                        .
                    </p>
                </div>
                <div className="prompt-message">
                    <span>Already have an account?</span>
                    <a href={process.env.REACT_APP_LOGIN_URL} className="login-btn">
                        LOGIN
                    </a>
                </div>
                <div className="top-clubsBox mtComp">
                    <div className="club_tip">Trusted by clubs at</div>
                    <div className="r-center">
                        {clubLists.map((item, i) => {
                            return (
                                <div
                                    className="clubImg_box"
                                    key={i}
                                    style={{ display: i > 3 && deviceType !== 'pc' ? 'none' : 'block' }}
                                >
                                    <img src={item} className="clubs_img" alt="" />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
            <img src={SignImg} className="SignImg-right" alt="" />
        </div>
    );
};

export default CompleteInformation;
