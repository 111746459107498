import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './style/index.scss';
import { Button, Modal, Input } from 'antd';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination, EffectFade } from 'swiper/modules';
import HomeFooter from './components/home-footer';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import api from '@/api/login';
import { useNavigate } from 'react-router-dom';
import '../../assets/animate.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import useLoading from '@/hook/useLoading';
import Club1 from '@/assets/images/website/1.svg';
import Club2 from '@/assets/images/website/2.svg';
import Club3 from '@/assets/images/website/3.svg';
import Club4 from '@/assets/images/website/4.svg';
import Club5 from '@/assets/images/website/5.svg';
import Club6 from '@/assets/images/website/6.svg';
import Club7 from '@/assets/images/website/7.svg';
const Home = () => {
    const navigate = useNavigate();
    const videoRef = useRef<any>(null);
    const { setLoadingStatus } = useLoading();
    const { deviceType } = useSelector((state: any) => state.state);
    const clubLists = [Club1, Club2, Club3, Club4, Club5, Club6, Club7];
    const swiperPcLists = [
        require('@/assets/images/swiper-bg/PC Image-1.png'),
        require('@/assets/images/swiper-bg/PC Image-2.png'),
        require('@/assets/images/swiper-bg/PC Image-3.png'),
        require('@/assets/images/swiper-bg/PC Image-4.png'),
        require('@/assets/images/swiper-bg/PC Image-5.png'),
        require('@/assets/images/swiper-bg/PC Image-6.png'),
    ];
    const swiperMobileLists = [
        require('@/assets/images/swiper-bg/Mobile Image-1.png'),
        require('@/assets/images/swiper-bg/Mobile Image-2.png'),
        require('@/assets/images/swiper-bg/Mobile Image-3.png'),
        require('@/assets/images/swiper-bg/Mobile Image-4.png'),
        require('@/assets/images/swiper-bg/Mobile Image-5.png'),
        require('@/assets/images/swiper-bg/Mobile Image-6.png'),
    ];
    const [open, setOpen] = useState(false);
    const openVideo = () => {
        setOpen(true);
        videoRef?.current?.play();
    };
    const closeVideo = () => {
        const video = videoRef.current;
        videoRef?.current?.pause();
        video.currentTime = 0;
        setOpen(false);
    };
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<span class="' + className + '">' + '</span>';
        },
    };
    AOS.init({
        duration: 350, // 动画持续时间
        easing: 'ease-in-out', // 缓动函数
        delay: 100, // 延迟时间
        once: true, // 仅触发一次
    });
    return (
        <div className="home-page">
            {deviceType === 'pc' ? (
                <div className="nav_top r-center">
                    <div className="top-tit r-center">
                        NARDO IS CURRENTLY OPEN TO SELECT INVITED CLUBS
                        <span className="join_text" onClick={() => navigate('/completeInformation')}>
                            JOIN THE WAITLIST
                        </span>
                        <img
                            src={require('@/assets/images/website/arrow-up-right.png')}
                            className="arrowUpRight-img"
                            alt=""
                        />
                    </div>
                </div>
            ) : null}
            <section className="content-box">
                <div className="r-left contentTop_box">
                    <img src={require('@/assets/images/website/logo.png')} className="logo" alt="" />
                    <Button
                        className="r-primaryHollow_btn join_btn_top"
                        style={{ backgroundColor: 'transparent' }}
                        onClick={() => navigate('/completeInformation')}
                    >
                        JOIN WAITLIST
                    </Button>
                </div>
                <div className="r-left contentTop_textView">
                    <Swiper
                        spaceBetween={0}
                        slidesPerView={1}
                        // effect={'fade'}
                        loop={true}
                        autoplay={{ delay: 5000 }}
                        style={{ width: '100%' }}
                        pagination={pagination}
                        modules={[Autoplay, Pagination]}
                    >
                        {swiperPcLists.map((item, i) => {
                            return (
                                <SwiperSlide key={i} style={{ width: '100%' }}>
                                    <img
                                        src={deviceType === 'pc' ? item : swiperMobileLists[i]}
                                        className="swiper-img"
                                        alt=""
                                    />
                                </SwiperSlide>
                            );
                        })}
                    </Swiper>
                    <div data-aos="fade-left" className="contentTop_textRight">
                        <div className="top-tit">
                            LEVEL-UP YOUR SPORTS CLUB <span className="primary-color">APPAREL GAME</span>
                        </div>
                        <div className="top-tip">
                            The Nardo Experience - an all in one design and ordering platform to save you substantial
                            time and money on your club's apparel.
                        </div>
                        {deviceType === 'pc' ? (
                            <div className="r-left top-joinBox">
                                <Button
                                    className="r-primary_btn join_btn"
                                    onClick={() => navigate('/completeInformation')}
                                >
                                    JOIN WAITLIST
                                </Button>
                            </div>
                        ) : (
                            <div className="baseSize">
                                <Button
                                    className="r-primary_btn join_btn"
                                    onClick={() => navigate('/completeInformation')}
                                >
                                    JOIN WAITLIST
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
                <div className="content_newBox">
                    <div className="top-clubsBox">
                        <div className="club_tip">Trusted by: </div>
                        <div className="r-center">
                            {clubLists.map((item, i) => {
                                return (
                                    <div key={i} style={{ display: i > 3 && deviceType !== 'pc' ? 'none' : 'block' }}>
                                        <img src={item} className="clubs_img" alt="" />
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="works-box">
                        <div className="feature_text" data-aos="zoom-in-down">
                            FEATURES
                        </div>
                        <div className="works_tit" data-aos="fade-up">
                            HOW IT WORKS
                        </div>
                        <div className="works_tip" data-aos="fade-left">
                            Your supply, reimagined.
                        </div>
                        <div className="r-left worksItemBox" data-aos="zoom-in-up">
                            <div className="worksItem">
                                <img src={require('@/assets/images/website/works1.png')} className="works_img" alt="" />
                                <div className="workStep_text">
                                    <div className="works_step r-left">
                                        <span className="stepNum_box">1</span>DESIGN
                                    </div>
                                    <div className="works_tips">
                                        Our AI inspired design assistant
                                        <img
                                            src={require('@/assets/images/website/AssistantLogo.png')}
                                            className="AssistantLogo_img"
                                            alt=""
                                        />
                                        will create an entire custom catalogue with your club's colours and logos at the
                                        click of a button.
                                    </div>
                                </div>
                            </div>
                            <div className="worksItem" data-aos="zoom-in-up">
                                <img src={require('@/assets/images/website/works2.png')} className="works_img" alt="" />
                                <div className="workStep_text">
                                    <div className="works_step r-left">
                                        <span className="stepNum_box">2</span>SHOP
                                    </div>
                                    <div className="works_tips">
                                        Shop from your unique catalogue, saving money with pre-set bundles while
                                        customising your order in a similar nature to shopping from an e-commerce store.
                                        Manage sizing and quantities with ease.
                                    </div>
                                </div>
                            </div>
                            <div className="worksItem" data-aos="zoom-in-up">
                                <img src={require('@/assets/images/website/works3.png')} className="works_img" alt="" />
                                <div className="workStep_text">
                                    <div className="works_step r-left">
                                        <span className="stepNum_box">3</span>DASHBOARD
                                    </div>
                                    <div className="works_tips">
                                        Your home base for everything gear related. See real time updates and order
                                        tracking, action items, file storage, invoicing, designs and communication all
                                        in one streamlined dashboard.
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="actionBox">
                        <div className="actionLeft_box" data-aos="zoom-in-down">
                            <div className="action_tit">
                                THE NEW WAY TO DESIGN AND
                                <div style={{ color: '#DCFF00' }}>SHOP SPORTS CLUB APPAREL</div>
                            </div>
                            <div className="action_tip">
                                See how the Nardo Experience is redefining the Teamwear industry with state of the art
                                technology that streamlines the entire design and ordering process, giving your club
                                access to elite designs and products while saving you time and money!
                            </div>
                            {/* <Button className="r-primaryHollow_btn video_btn" onClick={() => openVideo()}>
                            Watch video
                        </Button> */}
                        </div>
                        <div className="actionRight_box" data-aos="flip-left">
                            <img src={require('@/assets/images/website/action.png')} className="action_img" alt="" />
                            {/* <img
                            src={require('@/assets/images/website/play.png')}
                            className="play_icon"
                            alt=""
                            onClick={() => openVideo()}
                        /> */}
                        </div>
                        {deviceType !== 'pc' ? (
                            <>
                                <div className="action_tit_mb">
                                    THE NEW WAY TO DESIGN <br></br>AND
                                    <span style={{ color: '#DCFF00' }}> SHOP SPORTS CLUB APPAREL</span>
                                </div>
                                <div className="action_tip_mb">
                                    See how the Nardo Experience is redefining the Teamwear industry with state of the
                                    art technology that streamlines the entire design and ordering process, giving your
                                    club access to elite designs and products while saving you time and money!
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="projectTextBox" data-aos="fade-right">
                        THE FUTURE OF
                        <span className="primary-color">
                            SPORTS <br />
                            TEAMWEAR.
                        </span>
                    </div>
                    <div className="projectTipBox" data-aos="fade-right">
                        From the Co Founder of Australia's leading streetwear brand Geedup Co, Nardo is a forward
                        thinking teamwear brand, with a tech platform that has revolutionised the creation, shopping and
                        ordering processes of the best looking sports apparel in the game.
                    </div>
                    <div className="joinNardoBox r-top" data-aos="fade-down">
                        <div className="joinNardo-left">
                            {deviceType === 'pc' ? (
                                <div className="apparel_tit">
                                    JOIN THE <br />
                                    <span className="primary-color">REVOLUTION</span>
                                </div>
                            ) : (
                                <div className="apparel_tit">
                                    JOIN THE <span className="primary-color">REVOLUTION</span>
                                </div>
                            )}
                            <div className="apparel_tip">
                                Join the waitlist and become one of the few exclusive clubs invited to use the platform
                                today.
                            </div>
                        </div>
                        <div className="joinNardo-right">
                            <Button
                                className="r-primary_btn join_btn_ft"
                                onClick={() => navigate('/completeInformation')}
                            >
                                JOIN WAITLIST
                            </Button>
                            {deviceType === 'pc' ? (
                                <div className="joinMini-tip">
                                    Nardo is currently open to selected invited clubs, if you are interested,
                                    <span className="join-link" onClick={() => navigate('/completeInformation')}>
                                        join the waitlist
                                    </span>
                                </div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </section>
            <HomeFooter></HomeFooter>

            <Modal
                title=""
                open={open}
                onCancel={closeVideo}
                className="r-modal modal_no_padding"
                maskClosable={false}
                centered={true}
                // closeIcon={<></>}
                footer={''}
                key={`videoDialog-enterM`}
                getContainer={false}
            >
                <video controls={true} className="dialogVideo-website" ref={videoRef} playsInline>
                    {/* <source src={require('@/assets/video/test7.mp4')} type="video/mp4" /> */}
                    Your browser does not support the video tag.
                </video>
            </Modal>
        </div>
    );
};
export default Home;
