import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './style/index.scss';
import { Button, Input } from 'antd';
import api from '@/api/login';
import { useNavigate } from 'react-router-dom';
import '../../assets/animate.css';
import useLoading from '@/hook/useLoading';
import HomeFooter from './components/home-footer';
const Policy = () => {
    const thisYear = window.utils.getCurremtYear();
    const navigate = useNavigate();
    const { setLoadingStatus } = useLoading();
    const { deviceType } = useSelector((state: any) => state.state);
    const [state, setState] = useState({
        email: '',
    });
    const vaildeEmail = () => {
        if (!state.email || !window.utils.isEmail(state.email)) {
            window.format.alert('error', 'Please enter a valid email address');
            return false;
        }
        return true;
    };
    const nextStep = () => {
        if (!vaildeEmail()) return;
        setLoadingStatus(true);
        const pat = {
            email: state.email,
        };
        api.userSubscribe(pat, res => {
            const { data, code, message } = res;
            setLoadingStatus(false);
            if (code === 200) {
                setLoadingStatus(false);
                setState({ email: '' });
                window.format.alert('success', 'Subscribed successfully');
            } else {
                window.format.alert('error', message, code);
            }
        });
    };
    return (
        <div className="home-page policy-main">
            <section className="content-box lineBtm-box">
                <div className="r-left contentTop_box">
                    <img
                        style={{ cursor: 'pointer' }}
                        src={require('@/assets/images/website/logo.png')}
                        className="logo"
                        alt=""
                        onClick={() => navigate('/webSite')}
                    />
                    <Button
                        className="r-primaryHollow_btn join_btn_top"
                        onClick={() => navigate('/completeInformation')}
                    >
                        JOIN WAITLIST
                    </Button>
                </div>
                <section className="r-center">
                    <div className="textContent-box">
                        <p className="fz16">Current as of 1 July 2024</p>
                        <p className="fz-title fz48">PRIVACY POLICY</p>
                        <p className="fz20" style={{ marginBottom: 96 }}>
                            This Privacy Policy describes how your personal information is collected, used, and shared
                            when you visit or make a purchase from nardo-exp.com (the "Site").
                        </p>
                        <p className="fz-title">PERSONAL INFORMATION WE COLLECT</p>
                        <p style={{ paddingBottom: 20 }}>
                            When you visit the Site, we automatically collect certain information about your device,
                            including information about your web browser, IP address, time zone, and some of the cookies
                            that are installed on your device. Additionally, as you browse the Site, we collect
                            information about the individual web pages or products that you view, what websites or
                            search terms referred you to the Site, and information about how you interact with the Site.
                            We refer to this automatically-collected information as "Device Information".
                        </p>
                        <p className="fz-title">WE COLLECT DEVICE INFORMATION USING THE FOLLOWING TECHNOLOGIES:</p>
                        <p>
                            - "Cookies" are data files that are placed on your device or computer and often include an
                            anonymous unique identifier. For more information about cookies, and how to disable cookies,
                            visit
                            <a href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer">
                                http://www.allaboutcookies.org
                            </a>
                            .<br></br>- "Log files" track actions occurring on the Site, and collect data including your
                            IP address, browser type, Internet service provider, referring/exit pages, and date/time
                            stamps.
                            <br></br>- "Web beacons", "tags", and "pixels" are electronic files used to record
                            information about how you browse the Site.
                            <br></br>
                            <br></br>
                            Additionally, when you make a purchase or attempt to make a purchase through the Site, we
                            collect certain information from you, including your name, billing address, shipping
                            address, payment information (including credit card numbers), email address, and phone
                            number. We refer to this information as "Order Information".
                            <br></br>
                            <br></br>
                            When we talk about "Personal Information" in this Privacy Policy, we are talking both about
                            Device Information and Order Information.
                        </p>
                        <p className="fz-title">HOW DO WE USE YOUR PERSONAL INFORMATION?</p>
                        <p>
                            We use the Information that we collect generally to fulfil any orders placed through the
                            Site (including processing your payment information, arranging for shipping, and providing
                            you with invoices and/or order confirmations). Additionally, we use this Order Information
                            to:
                            <br></br>- Communicate with you;
                            <br></br>- Screen our orders for potential risk or fraud; and
                            <br></br>- When in line with the preferences you have shared with us, provide you with
                            information or advertising relating to our products or services.
                            <br></br>
                            <br></br>
                            We use the Device Information that we collect to help us screen for potential risk and fraud
                            (in particular, your IP address), and more generally to improve and optimise our Site (for
                            example, by generating analytics about how our customers browse and interact with the Site,
                            and to assess the success of our marketing and advertising campaigns).
                        </p>
                        <p className="fz-title">SHARING YOU PERSONAL INFORMATION</p>
                        <div>
                            We share your Personal Information with third parties to help us use your Personal
                            Information, as described above. We also use Google Analytics and Fullstory to help us
                            understand how our customers use the Site -- you can read more about how Google uses your
                            Personal Information here:
                            <a href="https://www.google.com/intl/en/policies/privacy/" target="_blank" rel="noreferrer">
                                https://www.google.com/intl/en/policies/privacy/
                            </a>
                            . You can also opt-out of Google Analytics here:
                            <a href="https://tools.google.com/dlpage/gaoptout" target="_blank" rel="noreferrer">
                                https://tools.google.com/dlpage/gaoptout
                            </a>
                            . And Fullstory here:
                            <a href="https://www.fullstory.com/legal/privacy-policy/" target="_blank" rel="noreferrer">
                                https://www.fullstory.com/legal/privacy-policy/
                            </a>
                            <br></br>
                            <br></br>
                            Finally, we may also share your Personal Information to comply with applicable laws and
                            regulations, to respond to a subpoena, search warrant or other lawful request for
                            information we receive, or to otherwise protect our rights.
                        </div>
                        <p className="fz-title">BEHAVIOURAL ADVERTISING</p>
                        <p>
                            As described above, we use your Personal Information to provide you with targeted
                            advertisements or marketing communications we believe may be of interest to you. For more
                            information about how targeted advertising works, you can visit the Network Advertising
                            Initiative's ("NAI") educational page at
                            <a
                                href="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work"
                                target="_blank"
                                style={{ marginLeft: 5 }}
                                rel="noreferrer"
                            >
                                http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work
                            </a>
                            .
                        </p>
                        <p className="fz-title">YOU CAN OPT OUT OF TARGETED ADVERTISING BY USING THE LINKS BELOW:</p>
                        <div>
                            - Facebook:
                            <a href="https://www.facebook.com/settings/?tab=ads" target="_blank" rel="noreferrer">
                                https://www.facebook.com/settings/?tab=ads
                            </a>
                            <br></br>- Google:
                            <a href="https://www.google.com/settings/ads/anonymous" target="_blank" rel="noreferrer">
                                https://www.google.com/settings/ads/anonymous
                            </a>
                            <br></br>- Bing:
                            <a
                                href="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads"
                                target="_blank"
                                rel="noreferrer"
                            >
                                https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads
                            </a>
                            <br></br>
                            <br></br>
                            Additionally, you can opt out of some of these services by visiting the Digital Advertising
                            Alliance's opt-out portal at:
                            <a href="http://optout.aboutads.info/" target="_blank" rel="noreferrer">
                                http://optout.aboutads.info/
                            </a>
                            .
                        </div>
                        <p className="fz-title">DO NOT TRACK</p>
                        <p>
                            Please note that we do not alter our Site's data collection and use practices when we see a
                            Do Not Track signal from your browser.
                        </p>
                        <p className="fz-title">YOUR RIGHTS</p>
                        <p>
                            If you are a European resident, you have the right to access personal information we hold
                            about you and to ask that your personal information be corrected, updated, or deleted. If
                            you would like to exercise this right, please contact us through the contact information
                            below.
                            <br />
                            <br />
                            Additionally, if you are a European resident we note that we are processing your information
                            in order to fulfil contracts we might have with you (for example if you make an order
                            through the Site), or otherwise to pursue our legitimate business interests listed above.
                            Additionally, please note that your information will be transferred outside of Europe,
                            including to Canada and the United States.
                        </p>
                        <p className="fz-title">DATA RETENTION</p>
                        <p>
                            When you place an order through the Site, we will maintain your Order Information for our
                            records unless and until you ask us to delete this information.
                        </p>
                        <p className="fz-title">CHANGES</p>
                        <p>
                            We may update this privacy policy from time to time in order to reflect, for example,
                            changes to our practices or for other operational, legal or regulatory reasons.
                        </p>
                        <p className="fz-title">CONTACT US</p>
                        <p>
                            For more information about our privacy practices, if you have questions, or if you would
                            like to make a complaint, please contact us by e-mail at founders@nardo-exp.com
                        </p>
                    </div>
                </section>
            </section>
            <HomeFooter showTopLine={deviceType === 'pc'}></HomeFooter>
        </div>
    );
};
export default Policy;
